import { Component } from 'react';
import PropTypes from 'prop-types';
import NavFooter from '~/components/NavFooter';
import Markdown from '~/components/Markdown';
import defaultLogo from '~/images/exaptive-logo-horizontal.png';
import styles from './styles.module.css';

const ROOT_FONT_SIZE = 14;
const HEIGHT_REM_LIMIT = 44;

export default class CityGoalContainer extends Component<{
  logo?: string;
  description?: string;
  children: JSX.Element;
}> {
  static propTypes = {
    logo: PropTypes.string,
    description: PropTypes.string,
    children: PropTypes.node.isRequired
  };

  state = {
    height: 0,
    width: 0
  };

  updateSize = (): void => {
    this.setState({
      height: window.innerHeight,
      width: window.innerWidth
    });
  };

  componentWillUnmount(): void {
    window.removeEventListener('resize', this.updateSize);
  }

  componentDidMount(): void {
    window.addEventListener('resize', this.updateSize);

    this.updateSize();
  }

  render(): JSX.Element {
    const { logo, description, children } = this.props;
    const leftLogo = logo || defaultLogo;
    const isVertical =
    this.state.height > this.state.width ||
    this.state.height / ROOT_FONT_SIZE < HEIGHT_REM_LIMIT;

    return (
      <div className={styles.container} data-testid="city-goal-container">
        <div className={styles.left}>
          <div className={styles.innerContainer}>
            <img
              src={leftLogo}
              alt="exaptive"
              className={styles.logo}
              data-testid="city-goal-container-logo" />

            <div className={styles.description}>
              <Markdown markdown={description || ''} />
            </div>
          </div>
        </div>
        <div className={styles.right}>
          <div className={styles.innerContainer}>{children}</div>
        </div>
        <NavFooter
          positionBottom={!isVertical}
          transparent={true}
          poweredlight={!isVertical} />

      </div>);

  }
}