import { Container } from 'reactstrap';

const IddoWelcome = ({ title, subTitle, children, toggleContent }) => {
  const hasTitle = title || subTitle;

  return (
    <>
      <div className="iddo-login-children">
        {hasTitle && (
          <div className="iddo-login-title-container">
            {title && <h4 data-testid="auth-panel-title">{title}</h4>}
            {subTitle && (
              <span className="iddo-login-subtitle">{subTitle}</span>
            )}
          </div>
        )}
        {children}
      </div>
      {toggleContent && (
        <Container className="iddo-login-toggle-content">
          {toggleContent}
        </Container>
      )}
    </>
  );
};

export default IddoWelcome;
