import { Container } from 'reactstrap';
import "./styles.css?__remix_sideEffect__";

const PublicViewLanding = (props) => {
  const { title, subTitle, className, children, toggleContent } = props;
  const hasTitle = title || subTitle;

  return (
    <div className={`login ${className}`}>
      <Container className="login-container">
        <div className="login-body">
          <div className="login-children">
            {hasTitle &&
            <div className="login-title-container">
                {title && <h4 data-testid="auth-panel-title">{title}</h4>}
                {subTitle && <span className="login-subtitle">{subTitle}</span>}
              </div>}

            {children}
          </div>
          {toggleContent &&
          <Container className="login-toggle-content">
              {toggleContent}
            </Container>}

        </div>
      </Container>
    </div>);

};

export default PublicViewLanding;