import { useEffect, useState } from 'react';
import { Container, Row, Col, Modal } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faFileAlt,
  faGlobeAmericas,
  faUsersMedical,
  faSearchPlus,
  faChevronRight } from
'@fortawesome/pro-regular-svg-icons';
import { useLocation, Link } from 'react-router-dom';
import axios from 'axios';
import qs from 'qs';
import NavFooter from '~/components/NavFooter';
import PrivacyBanner from '../../../PrivacyBanner';
import "./styles.css?__remix_sideEffect__";

const icons = {
  faFileAlt,
  faGlobeAmericas,
  faUsersMedical,
  faSearchPlus,
  faChevronRight
};

const preLoadedSections = [
{
  title: 'Study-level Data on Trial Registry Records',
  icons: [],
  linkText: 'Explore COVID-19 Living Systematic Review',
  link: '/public/overview'
},
{
  title: 'Individual Patient Data on Covid-19 Patients',
  icons: [],
  linkText:
  'Browse COVID-19 Clinical Data Inventory & be able to request access',
  link: '/cognitive/view/dac096eb-a38d-4181-9f1f-e420376429a8'
}];


export default IddoContainer;

function IddoContainer(props) {
  const { className, children } = props;
  const [sections, setSections] = useState([]);
  const location = useLocation();
  const authRoutes = [
  '/cognitive/auth-redirect',
  '/cognitive/auth/login-password',
  '/cognitive/auth/sign-up',
  '/cognitive/auth/forgot-password',
  '/cognitive/auth/reset-password',
  '/cognitive/auth/activate-user'];

  const queryParams = qs.parse(location.search, {
    ignoreQueryPrefix: true,
    allowDots: true
  });

  const [modal, setModal] = useState(
    authRoutes.includes(location.pathname) || !!queryParams.showLogin
  );
  const [isPrivacyMessageOpen, setIsPrivacyMessageOpen] = useState(true);
  const _closePrivacyMessage = () => {
    setIsPrivacyMessageOpen(false);
  };

  const toggleModal = () => setModal(!modal);

  useEffect(() => {
    (async () => {
      let loadingSections;
      try {
        const { data } = await axios('https://a2b.exaptive.city/iddo/getStats');
        loadingSections = data.sections;
      } catch {
        loadingSections = preLoadedSections;
      }
      loadingSections = loadingSections.map((s) => ({
        ...s,
        icons: s.icons.map((i) => ({ ...i, icon: icons[i.icon] }))
      }));
      setSections(loadingSections);
    })();
  }, []);

  return (
    <div>
      <Container className="iddo-top-container" fluid>
        <div className="iddo-login-link" onClick={() => toggleModal()}>
          Login
        </div>
        <Row className="iddo-top-content">
          <Col className="iddo-top-text">
            <div className="iddo-top-header">
              What is the IDDO Cognitive City and how do I use it?
            </div>
            <div className="iddo-top-description">
              The Infectious Diseases Data Observatory (IDDO) assembles
              clinical, laboratory and epidemiological data on a collaborative
              platform to be shared with the research and humanitarian
              communities to generate new evidence and insights. On the IDDO
              Cognitive City you can view the types and volumes of data
              available, explore how the data can apply to your research
              question, and request access to the data.
            </div>
          </Col>
          <Col className="iddo-top-image">
            <img
              src="https://www.exaptive.com/hubfs/misc-images/IDDO-logo-transparent-background.png"
              alt="IDDO"
              className="iddo-top-logo" />

          </Col>
        </Row>
      </Container>
      <Container fluid>
        <Row className="iddo-bottom-container">
          {sections.map((section) =>
          <Col key={section.title} className="iddo-section-container">
              <div className="iddo-section">
                <div className="iddo-section-header">{section.title}</div>
                <div className="iddo-section-icons">
                  {section.icons.map((icon) =>
                <div className="iddo-stat" key={icon.count}>
                      <FontAwesomeIcon
                    icon={icon.icon}
                    className="iddo-stat-icon" />

                      <div className="iddo-stat-count">{icon.count}</div>
                      <div className="iddo-stat-text">{icon.text}</div>
                    </div>
                )}
                </div>
                <Link to={section.link} className="iddo-section-button">
                  {section.linkText}
                  <FontAwesomeIcon
                  icon={faChevronRight}
                  className="iddo-button-icon" />

                </Link>
              </div>
            </Col>
          )}
        </Row>
      </Container>
      <NavFooter />
      <PrivacyBanner
        isOpen={isPrivacyMessageOpen}
        toggle={_closePrivacyMessage} />

      <Modal
        isOpen={modal}
        toggle={toggleModal}
        className="iddo-login-modal"
        fade={location?.state?.email === undefined}>

        <div className={`iddo-login ${className}`}>
          <Container className="iddo-login-container">
            <div className="iddo-login-body">{children}</div>
          </Container>
        </div>
      </Modal>
    </div>);

}