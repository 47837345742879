import PropTypes from 'prop-types';
import { Container } from 'reactstrap';
import "../../styles.css?__remix_sideEffect__";
const cssBlock = 'auth-panel';

const AuthPanelBody = (props) => {
  const hasTitle = props.title || props.subTitle;
  return (
    <>
      <div className={`${cssBlock}__children`}>
        {hasTitle &&
        <div className={`${cssBlock}__title-container`}>
            {props.title &&
          <h4 className="mb-2" data-testid="auth-panel-title">
                {props.title}
              </h4>}

            {props.subTitle &&
          <span className={`${cssBlock}__subtitle`}>{props.subTitle}</span>}

          </div>}

        {props.children}
      </div>
      {props.toggleContent &&
      <Container className={`${cssBlock}-toggle-content`}>
          {props.toggleContent}
        </Container>}

    </>);

};

AuthPanelBody.defaultProps = {
  toggleContent: null,
  className: ''
};

AuthPanelBody.propTypes = {
  className: PropTypes.string,
  toggleContent: PropTypes.node,
  title: PropTypes.string,
  subTitle: PropTypes.oneOfType([PropTypes.node, PropTypes.string])
};

export default AuthPanelBody;