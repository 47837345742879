import { useState } from 'react';
import { Button, Modal, Navbar, NavLink, NavbarBrand } from 'reactstrap';
import { useLocation, useNavigate } from 'react-router-dom';
import qs from 'qs';
import { useCity } from '~/services/hooks/useCity';
import Loadable from '~/components/Loadable';
import PrivacyBanner from '../../../PrivacyBanner';
import "./styles.css?__remix_sideEffect__";
import styles from './styles.module.css';

const PublicWorkspace = Loadable({
  loader: () => import('~/components/workspace/scenes/PublicWorkspace')
});
const Viewer = Loadable({
  loader: () => import('~/components/workspace/scenes/Viewer')
});

const PublicViewLandingContainer = (props) => {
  const { children, landingPageConfig } = props;
  const { viewUuid } = landingPageConfig;

  const [isPrivacyMessageOpen, setIsPrivacyMessageOpen] = useState(true);
  const _closePrivacyMessage = () => {
    setIsPrivacyMessageOpen(false);
  };

  const location = useLocation();
  const navigate = useNavigate();

  const authRoutes = [
  '/cognitive/auth-redirect',
  '/cognitive/auth/login-password',
  '/cognitive/auth/sign-up',
  '/cognitive/auth/forgot-password',
  '/cognitive/auth/reset-password',
  '/cognitive/auth/activate-user',
  '/cognitive/join'];

  const queryParams = qs.parse(location.search, {
    ignoreQueryPrefix: true,
    allowDots: true
  });

  const [modaIsOpen, setModalIsOpen] = useState(
    authRoutes.includes(location.pathname) || !!queryParams.showLogin
  );

  const toggleModal = () => {
    const _nextModal = !modaIsOpen;
    setModalIsOpen(_nextModal);
    if (!_nextModal) {
      navigate('/cognitive/auth/landing');
    }
  };

  const navBrandStyles = {
    flex: '0'
  };

  const cityContext = useCity();
  const logo =
  <NavLink to="/cognitive" className="nav-link">
      <img
      src={cityContext.city.style.logo}
      alt="Logo"
      className={styles.logo} />

    </NavLink>;


  return (
    <div className={styles.pageContainer}>
      <Navbar className={styles.navBar}>
        <NavbarBrand
          tag="div"
          className={styles.navBrand}
          style={navBrandStyles}>

          {logo}
        </NavbarBrand>
        <Button className="login-link" onClick={() => toggleModal()}>
          Login
        </Button>
      </Navbar>
      <Modal
        isOpen={modaIsOpen}
        toggle={toggleModal}
        className="login-modal"
        fade={location?.state?.email === undefined}>

        {children}
      </Modal>
      <PublicWorkspace>
        <Viewer view={viewUuid} />
      </PublicWorkspace>
      <PrivacyBanner
        isOpen={isPrivacyMessageOpen}
        toggle={_closePrivacyMessage} />

    </div>);

};

export default PublicViewLandingContainer;