import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'reactstrap';
import { get } from 'lodash';
import Markdown from '~/components/Markdown';
import { CityContext } from '~/services/Contexts';
import styles from './styles.module.css';

const DEFAULT_MSG = [
`
  This City is an Exaptive product. We collect data about the use of
  our software. Our goal is to understand our users' needs and improve
  the product. We don't sell the information to third-parties. Please read our
  `,
<a
  key="eula"
  href="https://www.exaptive.com/terms"
  target="_blank"
  rel="noopener noreferrer"
  onClick={(event) => event.stopPropagation()}
  className={styles.link}>

    subscription agreement
  </a>,
' and ',
<a
  key="privacypolicy"
  href="https://www.exaptive.com/privacy"
  target="_blank"
  rel="noopener noreferrer"
  onClick={(event) => event.stopPropagation()}
  className={styles.link}>

    privacy policy
  </a>,
` to learn more. By signing up or logging in, you are in agreement to the
  terms in those documents.`];


const PrivacyBanner = ({ toggle, isOpen, cityContext }) => {
  const hasCustomBanner = get(cityContext, 'cognitiveCity.terms.custom');
  const bannerText = get(cityContext, 'cognitiveCity.terms.bannerText');

  return (
    isOpen &&
    <div className={styles.banner}>
        <div className={styles.bannerText} data-testid="banner-text">
          {hasCustomBanner ? <Markdown markdown={bannerText} /> : DEFAULT_MSG}
          {hasCustomBanner &&
        <div className={styles.poweredContainer}>
              <a
            className={styles.poweredLink}
            href="https://www.exaptive.com/powered"
            target="_blank"
            rel="noopener noreferrer">

                Powered by Exaptive
              </a>
            </div>}

        </div>
        <Button
        onClick={toggle}
        className={`btn btn-ghost ${styles.bannerButton}`}
        data-testid="banner_okay-button">

          OKAY
        </Button>
      </div>);


};

PrivacyBanner.propTypes = {
  toggle: PropTypes.func.isRequired
};

const PrivacyBannerWrapper = (props) =>
<CityContext.Consumer>
    {(cityContext) => <PrivacyBanner cityContext={cityContext} {...props} />}
  </CityContext.Consumer>;


export default PrivacyBannerWrapper;