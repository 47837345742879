import { useCity } from '~/services/hooks/useCity';
import PublicViewPageBody from './landingPages/PublicView/PublicViewLandingPageBody';
import PublicViewContainer from './landingPages/PublicView/PublicViewLandingContainer';
import DefaultContainer from './landingPages/Default/DefaultLandingContainer';
import DefaultPageBody from './landingPages/Default/DefaultLandingPageBody';
import IddoContainer from './landingPages/Iddo/IddoLandingContainer';
import IddoPageBody from './landingPages/Iddo/IddoLandingPageBody';
import "./styles.css?__remix_sideEffect__";

const landingPageType = {
  CUSTOM: 'custom',
  PUBLIC_VIEW: 'publicView',
  DEFAULT: 'default'
};

const pageContainerComponent = {
  [landingPageType.CUSTOM]: CustomLandingPageContainer,
  [landingPageType.PUBLIC_VIEW]: PublicViewContainer,
  [landingPageType.DEFAULT]: DefaultContainer
};

const pageBodyComponent = {
  [landingPageType.CUSTOM]: CustomLandingPageBody,
  [landingPageType.PUBLIC_VIEW]: PublicViewPageBody,
  [landingPageType.DEFAULT]: DefaultPageBody
};

const AuthPanelBodyWrapper = (props) => {
  const { cognitiveCityConfig } = useCity();
  const landingPageConfig = cognitiveCityConfig.pages.landing;

  const LandingPage =
  pageBodyComponent[landingPageConfig?.type || landingPageType.DEFAULT];
  if (!LandingPage) {
    return <>Unsupported landing page</>;
  }
  return <LandingPage {...props} />;
};

export default AuthPanelBodyWrapper;

const AuthPanelContainerWrapper = (props) => {
  const { cognitiveCityConfig } = useCity();
  const landingPageConfig = cognitiveCityConfig.pages.landing;

  const LandingPage =
  pageContainerComponent[landingPageConfig?.type || landingPageType.DEFAULT];
  if (!LandingPage) {
    return <>Unsupported landing page</>;
  }
  return <LandingPage {...props} landingPageConfig={landingPageConfig} />;
};

export { AuthPanelContainerWrapper as AuthPanelContainer };

function CustomLandingPageBody(props) {
  const { cityInfo } = useCity();
  if (cityInfo.subdomain === 'iddo') {
    return <IddoPageBody {...props} />;
  }
  return <>Unsupported custom landing page</>;
}

function CustomLandingPageContainer(props) {
  const { cityInfo } = useCity();
  if (cityInfo.subdomain === 'iddo') {
    return <IddoContainer {...props} />;
  }
  return <>Unsupported custom landing page</>;
}