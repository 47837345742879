import { useState } from 'react';
import { Container } from 'reactstrap';
import CityGoalContainer from '~/components/CityGoalContainer';
import { useCity } from '~/services/hooks/useCity';
import PrivacyBanner from '../../../PrivacyBanner';

const cssBlock = 'auth-panel';

const AuthPanelContainer = props => {
  const { className, children, showPrivacyBanner = false } = props;
  const { city } = useCity();

  const [isPrivacyMessageOpen, setIsPrivacyMessageOpen] =
    useState(showPrivacyBanner);
  const _closePrivacyMessage = () => {
    setIsPrivacyMessageOpen(false);
  };

  return (
    <CityGoalContainer
      logo={city.style.logo}
      description={city.metadata.description}
    >
      <div
        className={`${cssBlock} ${className}`}
        data-testid="auth-panel-inner-container"
      >
        <Container className={`${cssBlock}__container`}>
          <div className={`${cssBlock}__body`}>{children}</div>
        </Container>
      </div>
      <PrivacyBanner
        isOpen={isPrivacyMessageOpen}
        toggle={_closePrivacyMessage}
      />
    </CityGoalContainer>
  );
};

export default AuthPanelContainer;
